.body{
    background-color: #330D0D;

}

.bodyWrapper{
    width: 100%;
    display: flex;
    @media screen and (max-width: 1300px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
}
.wrapper{
    font-family: 'Montserrat', sans-serif;
    padding: 40px 0 20px 0;
    color: #FFFFFF;
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 500px;
    overflow: hidden;
    display: flex;
    @media screen and (max-width: 1300px) {
        height: auto;
    }
}

.red{
    color: #C74444;
}


.text{
    font-size: 23px;
    width: 100%;
    float: left;
}

.title{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    gap: 5px;
    margin-bottom: 20px;

}



.images{
    padding: 30px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    @media screen and (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
}

.first{
    box-shadow: 10px -8px 0px 0px rgb(255, 255, 255);
    grid-column: 1;
    grid-row: 1;
}
.second{
    box-shadow: 10px -8px 0px 0px rgb(255, 255, 255);
    grid-column: 2;
    grid-row: 2;
  }