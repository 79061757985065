.wrapper{
    background-color: #330D0D;
    font-family: 'Montserrat', sans-serif;
    background-size: cover;
    display: flex;
    padding: 60px 0;
}

.inner{
    background-color: #000000;
    margin: auto;
    justify-content: space-between;
    text-align: center;
    font-size: 23px;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    padding: 40px;
    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
}

.title{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.text{
    font-size: 23px;
    color: white;
    margin: 0 auto 40px auto;
    width: 75%;
    b{
        color: #D04B4B;
    }
}

