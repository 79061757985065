.wrapper{
  background-color: #141414;
  padding: 60px 0;
  font-family: 'Montserrat', sans-serif;
}

.container{
  border-radius: 16px;
  padding: 25px 0;
  width: 100%;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
}

.button{
  display: block;
  margin: auto;
  width: 250px;
  height: 80px;
  transition: all 0.5s ease-out;

  img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &:hover{
    width: 230px;
    height: 72px;
  }
}

.title{
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #FFFFFF;
  b{
    color: #D04B4B;
  }
  @media screen and (max-width: 768px) {
    text-align: center
  }
}

.items{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
   display: block;
  }
}

.item{
  width: 40%;
  &:not(:last-child){
    margin-bottom: 100px;
  }

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.itemTitle{
  color: #C74444;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}

.itemText{
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}