.wrapper{
  background-color: #141414;
  padding: 60px 0;
  font-family: 'Montserrat', sans-serif;
}

.container{
  border-radius: 16px;
  display: flex;
  padding: 25px 0px;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
}

.text{
  display: flex;
  width: 40%;
  flex-direction: column;
  @media screen and (max-width: 920px) {
    width: 100%;
  }
}

.title{

  font-size: 40px;
  font-weight: 700;
  color: #FFF;
  margin: 0 auto 40px auto;
  padding: 0 0 10px 0;
  width: fit-content;
  b{
    color: #D04B4B;
  }
  @media screen and (max-width: 920px) {
    margin: 40px auto 20px auto;
  }
}

.description{
  font-size: 23px;
  color: white;
  margin: 0 0 40px 0;
  b{
    color: #D04B4B;
  }
}

.button{
  width: 200px;
  height: 60px;
  transition: all 0.5s ease-out;
  margin: auto 0 0 0;
  img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &:hover{
    width: 210px;
    height: 62px;
  }
  @media screen and (max-width: 920px) {
    margin: 0 0 40px 0;
  }
}

.slider{
  width: 45%;
  max-height: 500px;
  border-radius: 16px;
  //box-shadow: white;
  img{
    box-shadow: 10px -8px 0px 0px rgb(255, 255, 255);

  }
  @media screen and (max-width: 920px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.sliderImage{
  height: 450px;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}
