.wrapper{
    background: url("../../../../public/images/Aiming/back.png") no-repeat;
    font-family: 'Montserrat', sans-serif;
    background-size: cover;
    height: 450px;
    display: flex;
}

.inner{
    margin: auto;
    justify-content: space-between;
    padding: 100px 0;
}

.title{
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}


.button{
    margin: 40px auto 0 auto;
    width: 300px;
    height: 100px;
    transition: all 0.5s ease-out;
    img{
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    &:hover{
        width: 350px;
        height: 120px;
        margin: 39px auto 0 auto;
    }
    @media screen and (max-width: 400px) {
        width: 80%;
        height: 75px;
    }
}
