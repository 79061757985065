.headerWrapper{
  font-family: 'Montserrat', sans-serif;
  height: 80px;
  display: flex;
  position: fixed;
  z-index: 999;
  width: 100%;
}

.scrolled{
  background-color: #141414;
}

.inner{
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.logo{
  display: flex;
  gap: 10px;
  align-items: center;
  img{
    width: 65px;
    height: 65px;
  }
}

.logoName{
  border-left: 2px solid #fff;
  padding: 0 0 0 10px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.headerItems{
  display: flex;
  gap: 15px;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.burger{
  display: none;
  align-items: center;
  min-width: 30px;
  svg{
    width: 30px;
  }
  @media screen and (max-width: 992px) {
    display: flex;
  }
}

.headerItem{
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
  height: 100%;
  align-items: center;
  display: flex;
  color: white;
  &:hover{
    color: #D04B4B;
  }
  @media screen and (max-width: 1200px) {
    font-size: 16px;
    padding: 0 4px;
  }
}

.burgerItem{
  font-size: 16px;
}