.body{
  background-color: #141414;

}

.wrapper{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 60px 0 0 0;

}

.text{
    padding: 0 0 30px 0;
    font-size: 18px;
    color: #ffffff;
}

.logo{
    display: flex;
    width: 100px;
  img{
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    width: 200px;
  }
}

.rows{ 
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
}

.row{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    width: fit-content;
    height: auto;
    font-size: 15px;
  a{
    margin-bottom: 15px;
  }
}

a {
    color: #ffffff;
    text-decoration: none;
  }

  .button{
    width: 230px;
    height: 70px;
    transition: all 0.5s ease-out;
    img{
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    &:hover{
        width: 240px;
        height: 80px;
        margin: 0px auto 0 auto;
    }
}

  .line{
    height: 1px;
    background-color: #ffffff;
    margin: 10px 0;
  }
  .copyright{
    padding: 10px 0;
    font-size: 14px;
}

