.wrapper{
  background: url("../../../../public/images/banner/main-bg.png") no-repeat;
  font-family: 'Montserrat', sans-serif;
  background-size: cover;
  height: 550px;
  display: flex;
  width: 100%;
  @media screen and (max-width: 860px) {
    height: auto;
  }
}

.inner{
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
  @media screen and (max-width: 860px) {
    flex-direction: column-reverse;
    padding: 50px 0;
  }
}

.info{
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  @media screen and (max-width: 860px) {
    width: 100%;
  }
}

.title{
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #FFFFFF;
  b{
    color: #D04B4B;
  }
}

.subTitle{
  font-size: 23px;
  color: #FFF;
  margin-bottom: auto;
  @media screen and (max-width: 860px) {
    margin-bottom: 60px;
  }
}

.button{
  width: 220px;
  height: 70px;
  transition: all 0.5s ease-out;

  img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &:hover{
    width: 230px;
    height: 72px;
  }
}

.logo{
  width: 40%;
  display: flex;
  justify-content: center;
  img{
    width: 100%;
    height: 100%;
    @media screen and (max-width: 860px) {
      max-width: 400px;
    }
  }
  @media screen and (max-width: 860px) {
    width: 100%;
  }
}
