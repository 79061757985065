.wrapper{
    background: #141414;
    font-family: 'Montserrat', sans-serif;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner{
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    padding: 60px 0;
    font-size: 23px;
    width: 100%;
    color: rgb(255, 255, 255);
}

.title{
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.description{
    padding: 50px;
    text-align: center;
    @media screen and (max-width: 456px) {
        padding: 50px 0;
    }
}

.red{
    color: #C74444;
}

.buttonBlock{
    margin: 0 auto;
    width: 330px;
    height: 80px;
    @media screen and (max-width: 456px) {
        width: 100%;
    }
}

.button{

    width: 100%;
    height: 100%;
    font-size: 30px;
    padding: 10px 15px;
    background-color: #C74444;
    &:hover{
        background-color: #923232 !important;
    }
    span{
        height: 100%;
    }

}

.okButton{
    color: #FFFFFF;
    background-color: #C74444;
    &:hover{
        color: #FFFFFF!important;
        border-color: #923232 !important;
        background-color: #923232 !important;
    }
}