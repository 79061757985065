.wrapper{
    background-color: #330D0D;
    font-family: 'Montserrat', sans-serif;
    background-size: cover;
    display: flex;
    padding: 60px 0;
}

.inner{
    margin: auto;
    justify-content: space-between;
    text-align: center;
    font-size: 23px;
    color: rgb(255, 255, 255);
}

.title{
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding: 20px;
}

.red{
    color: #C74444;
}

.nom{
    margin-bottom: 20px;
}
.winners{
    color: rgb(163, 153, 153);
    padding: 20px;
}


